// Modules
import React from "react";
import { graphql } from "gatsby";

// Components
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import Layout from "../../components/layout";
import PageBuilder from "../../components/pageBuilder";
import Seo from "../../components/seo/seo";

// Helpers
import createBreadcrumbs from "../../helpers/createBreadcrumbs";
import { getVideoDataInPage } from "../../helpers/getVideoDataInPage";

// Styles
import * as styles from "../pages/pages.module.scss";

const ThankYouPage = ({ data, pageContext }) => {
  const { sanityThankYouPage } = data;

  const heroContent = sanityThankYouPage?._rawTabs?.content?.hero;
  const pageBuilderContent = sanityThankYouPage?._rawTabs?.content?.pageBuilder;
  const content = sanityThankYouPage?._rawTabs?.content;
  const seo = sanityThankYouPage?.tabs?._rawSeo;
  const heroTitle = heroContent.heroText.titleText;
  const { _createdAt, _updatedAt } = sanityThankYouPage;
  const HeroComponent = heroContent?.isRedesigned ? HeroRedesigned : Hero;
  const video = getVideoDataInPage(pageBuilderContent);

  const breadcrumbsList = createBreadcrumbs(content);

  const pageBuilderClassNamesObj = {
    textImage: styles.gridImgTextContainer,
    pbRichText: styles.textStyle,
    testimonial: styles.testimonialsPageWrapper,
  };

  return (
    <Layout>
      <Seo
        title={heroTitle}
        image={heroContent?.bgImage}
        breadcrumbs={breadcrumbsList}
        path={pageContext?.pagePath}
        datePublished={_createdAt}
        dateModified={_updatedAt}
        video={video}
        {...seo}
      />
      <HeroComponent
        imageClassName={styles.heroImg}
        breadcrumbs={breadcrumbsList}
        image={heroContent?.bgImage}
        subTitle={heroContent?.heroText.subtitleText}
        title={heroContent?.heroText.titleText}
      />
      {pageBuilderContent && (
        <div className={styles.pageMainContent}>
          {pageBuilderContent.map((item) => {
            if (item._type === "testimonial" || item._type === "textImage") {
              return (
                <div className={styles.smallWrapper} key={item._key}>
                  <PageBuilder
                    classNamesObj={pageBuilderClassNamesObj}
                    pageBuilderContents={[item]}
                  />
                </div>
              );
            }
            return (
              <PageBuilder
                classNamesObj={pageBuilderClassNamesObj}
                key={item._key}
                pageBuilderContents={[item]}
              />
            );
          })}
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    sanityThankYouPage(
      tabs: { content: { slug: { current: { eq: $slug } } } }
    ) {
      _createdAt
      _updatedAt
      tabs {
        _rawSeo
      }
      _rawTabs(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default ThankYouPage;
